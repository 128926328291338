import React from 'react'
import cx from 'classnames'
import { Popover } from '@headlessui/react'

import { useHoverablePopoverContext } from '../../util'


type OverlayProps = {
  className?: string
  withDefaultStyle?: boolean
}
const Overlay: React.FunctionComponent<OverlayProps> = ({ className, withDefaultStyle }) => {
  const { hoverClosePopoverPanel } = useHoverablePopoverContext()

  return (
    <Popover.Overlay
      className={cx(className, withDefaultStyle && `-z-1 fixed inset-0 bg-black opacity-50`)}
      onMouseEnter={hoverClosePopoverPanel}
    />
  )
}


export default Overlay
