import React, { type ReactNode, useCallback, useEffect, useMemo, useRef } from 'react'
import { Popover } from '@headlessui/react'

import Button from './components/Button/Button'
import Overlay from './components/Overlay/Overlay'
import { HoverablePopoverContext } from './util'
import type { HoverablePopoverContextValue } from './util'


export type HoverablePopoverProps = {
  className?: string
  groupOpenedPopoverCountRef?: React.MutableRefObject<number>
  children: ReactNode | (({ open, close }: { open: boolean, close: () => void }) => React.ReactElement)
}

type HoverablePopoverComponent = React.FunctionComponent<HoverablePopoverProps> & {
  Button?: typeof Button
  Overlay?: typeof Overlay
  Panel?: typeof Popover.Panel
  Group?: typeof Popover.Group
}

const HoverablePopover: HoverablePopoverComponent = (props) => {
  const { children, className, groupOpenedPopoverCountRef } = props

  const buttonRef = useRef<HTMLButtonElement>(null)
  const timeoutRef = useRef<NodeJS.Timeout>(null)
  const openStateRef = useRef<boolean>(false)
  const closeFnRef = useRef<() => void>(null)
  // we should restrict closePopoverPanel on mouse event if flyout was opened by keyboard
  const areMouseCloseEventsEnabledRef = useRef<boolean>(false)

  const openPopoverPanel = () => {
    clearTimeout(timeoutRef.current)

    // we should check if it already open
    if (!openStateRef.current) {
      // if we have open popover from another NavRootItem we should open this one immediately
      if (groupOpenedPopoverCountRef?.current > 0) {
        buttonRef.current?.click()
      }
      // if there no opened popovers we should open first one with delay to give the user a chance to pass through navbar to page content without opening nav popover
      else {
        timeoutRef.current = setTimeout(() => {
          buttonRef.current?.click()
        }, 250)
      }

      if (groupOpenedPopoverCountRef) {
        groupOpenedPopoverCountRef.current += 1
      }
    }
  }

  const hoverClosePopoverPanel = useCallback((event) => {
    event?.stopPropagation()

    if (!areMouseCloseEventsEnabledRef.current) {
      return
    }

    clearTimeout(timeoutRef.current)

    // close immediately if the user hovers another item
    if (groupOpenedPopoverCountRef?.current > 1) {
      if (openStateRef.current) {
        closeFnRef.current?.()
        // @ts-expect-error
        document.activeElement?.blur()
        groupOpenedPopoverCountRef.current -= 1
      }
    }
    // or close with delay to provide smooth experience
    else {
      timeoutRef.current = setTimeout(() => {
        if (openStateRef.current) {
          closeFnRef.current?.()
          // @ts-expect-error
          document.activeElement?.blur()
        }

        if (groupOpenedPopoverCountRef?.current > 0) {
          groupOpenedPopoverCountRef.current -= 1
        }
      }, 150)
    }
  }, [ groupOpenedPopoverCountRef ])

  // if use has opened flyout by keyboard but switched to mouse we restore mouseLeave events
  const handleMove = () => {
    areMouseCloseEventsEnabledRef.current = true
  }

  const hoverablePopoverContextValue = useMemo<HoverablePopoverContextValue>(() => ({
    buttonRef,
    hoverClosePopoverPanel,
  }), [ hoverClosePopoverPanel ])

  useEffect(() => {
    // block mouseLeave close event in case of pointer is out of Item area but it's opened via keyboard
    const handleKeyPress = () => {
      areMouseCloseEventsEnabledRef.current = false
    }
    // we restrict close action by click on the button
    // to avoid unexpected behavior when menu opens via hover and user click in the same moment so menu blinks (opened and immediately closed)
    const preventCloseOnClick = (event) => {
      clearTimeout(timeoutRef.current)

      if (event.detail > 0 && openStateRef.current) {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    // capture flag is required
    buttonRef.current.addEventListener('click', preventCloseOnClick, true)
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      clearTimeout(timeoutRef.current)
      document.removeEventListener('keydown', handleKeyPress)
      closeFnRef.current = null
    }
  }, [])

  return (
    <HoverablePopoverContext.Provider value={hoverablePopoverContextValue}>
      <Popover
        as="div"
        className={className}
        onMouseEnter={openPopoverPanel}
        onMouseMove={handleMove}
        onMouseLeave={hoverClosePopoverPanel}
      >
        {
          ({ open, close }) => {
            openStateRef.current = open
            closeFnRef.current = close

            return typeof children === 'function' ? children({ open, close }) : <>children</>
          }
        }
      </Popover>
    </HoverablePopoverContext.Provider>
  )
}


HoverablePopover.Button = Button
HoverablePopover.Overlay = Overlay
HoverablePopover.Panel = Popover.Panel
HoverablePopover.Group = Popover.Group

HoverablePopover.displayName = 'HoverablePopover'


export default HoverablePopover
