import React from 'react'
import cx from 'classnames'
import { Menu } from '@headlessui/react'

import Item from './components/Item/Item'


type ItemProps = React.ComponentProps<typeof Item>

export type DropdownProps = {
  className?: string
  children: React.ReactElement<{ isOpen?: boolean }>
  items: ItemProps[]
  placement?: 'bottomLeft' | 'bottomRight'
  buttonDataTestId?: string
}

const Dropdown: React.FunctionComponent<DropdownProps> = (props) => {
  const { children, className, items, placement = 'bottomLeft', buttonDataTestId } = props

  if (!React.isValidElement(children)) {
    throw new Error('Dropdown children should be a single valid element')
  }

  const rootClassName = cx('relative', className)
  const menuClassName = cx('z-tooltip absolute block rounded bg-white py-4 shadow', {
    'top-full right-0': placement === 'bottomRight',
    'top-full left-0': placement === 'bottomLeft',
  })

  return (
    <Menu as="div" className={rootClassName}>
      <Menu.Button aria-label="Menu" data-testid={buttonDataTestId}>
        {
          ({ open }) => React.cloneElement(children, { isOpen: open })
        }
      </Menu.Button>
      <Menu.Items className={menuClassName}>
        {
          items.map((itemProps, index) => (
            <Item
              key={index}
              {...itemProps}
            />
          ))
        }
      </Menu.Items>
    </Menu>
  )
}


export default React.memo(Dropdown)
