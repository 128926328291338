import React from 'react'
import cx from 'classnames'

import { Text, Image } from 'components/dataDisplay'
import type { TabsProps } from 'components/navigation'


type TabProps = {
  id: string
  tagId: string
  title?: Intl.Message | string
  titleContent?: React.ReactNode
  titleStyle?: TabsProps['titleStyle']
  gap?: TabsProps['gap']
  isActive: boolean
  activeColor?: TabsProps['activeColor']
  withMargin?: boolean
  imageSrc?: Image.Src
  imageLabel?: React.ReactNode
  onClick: (id: string) => void
}

const activeColorToClassName: Record<TabProps['activeColor'], string> = {
  'gold-50': 'border-gold-50',
  'white': 'border-white',
}

const gapToClassName: Record<TabProps['gap'], string> = {
  14: 'ml-14',
  24: 'ml-24',
  32: 'ml-32',
}

const Tab: React.FunctionComponent<TabProps> = (props) => {
  const { id, tagId, title, titleContent, titleStyle = 'h8', gap = 32, isActive, activeColor = 'gold-50', withMargin, imageSrc, imageLabel, onClick } = props

  const rootClassName = cx(
    'cursor-pointer whitespace-nowrap border-0 border-b-2 border-solid px-0 py-[9rem] first:ml-0', {
      'cursor-default': isActive,
      'border-transparent': !isActive,
      [gapToClassName[gap]]: withMargin,
      [activeColorToClassName[activeColor]]: isActive,
    })

  if (imageSrc) {
    return (
      <div
        id={tagId}
        className="relative flex w-[90rem] flex-col items-center gap-16"
        role="tab"
        tabIndex={isActive ? 0 : -1}
        aria-controls={`${tagId}-panel`}
        aria-selected={isActive}
        data-testid={`${id}Tab`}
        onClick={() => onClick(id)}
      >
        {imageLabel}
        <Image
          className={cx('w-[90rem] rounded-full border-2 shadow', isActive ? 'border-solid-gold-30' : 'border-light-beige cursor-pointer')}
          src={imageSrc}
          alt={id}
          remWidth={90}
        />
        <Text html className="whitespace-nowrap" message={title} style={titleStyle}>{titleContent}</Text>
      </div>
    )
  }

  return (
    <Text
      className={rootClassName}
      id={tagId}
      message={title}
      style={titleStyle}
      color={isActive ? activeColor : 'black'}
      tag="button"
      role="tab"
      tabIndex={isActive ? 0 : -1}
      aria-controls={`${tagId}-panel`}
      aria-selected={isActive}
      data-testid={`${id}Tab`}
      onClick={() => onClick(id)}
      html
    >
      {titleContent}
    </Text>
  )
}


export default React.memo(Tab)
